import React from "react"
import { VStack } from "lib/ui/Stack"
import { NavigationText } from "lib/ui/Navigation/NavigationText"
import { InternalLink } from "lib/navigation/Link/InternalLink"

interface SeriesPart {
  name: string
  slug: string
}

interface Props {
  parts: SeriesPart[]
}

export const SeriesTableOfContent = ({ parts }: Props) => {
  return (
    <VStack alignItems="start" gap={12}>
      <h2>Table of Content</h2>
      {parts.map(({ name, slug }, index) => (
        <InternalLink key={slug} to={slug}>
          <NavigationText text={`${index + 1}. ${name}`} />
        </InternalLink>
      ))}
    </VStack>
  )
}
