import React from "react"
import { graphql } from "gatsby"
import { BlogPost } from "blog/components/BlogPost"
import { MarkdownHTML } from "ui/markdown/MarkdownHTML"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SEO } from "seo/SEO"
import { PageContainer } from "lib/ui/page/PageContainer"
import { SeriesTableOfContent } from "blog/components/SeriesTableOfContent"
import { PostCategory } from "blog/entities/Post"
import { Footer } from "shared/components/Footer"

export interface BlogPostContext {
  slug: string
  isSeries: boolean
  isSeriesPart: boolean
  isSeriesRoot: boolean
  seriesPartsSlugRegex: string
  seriesRootSlug?: string
}

interface SeriesPartNode {
  fields: {
    slug: string
  }
  frontmatter: {
    partNumber: number
    shortTitle: string
  }
}

interface SeriesPartEdge {
  node: SeriesPartNode
}

interface PageQueryData {
  markdownRemark: {
    frontmatter: {
      date: string
      title: string
      description: string
      featuredImage: any
      youTubeVideo?: string
      github?: string
      demo?: string
      category: PostCategory
    }
    fields: {
      readingTime: {
        text: string
      }
    }
    html: string
  }
  seriesParts: {
    edges: SeriesPartEdge[]
  }
  seriesRoot: {
    fields: { slug: string }
    frontmatter: { title: string }
  }
}

interface Props {
  data: PageQueryData
  pageContext: BlogPostContext
}

export const BlogPostTemplate = ({ data, pageContext }: Props) => {
  const {
    markdownRemark: {
      frontmatter: {
        date,
        title,
        description,
        featuredImage,
        youTubeVideo,
        github,
        demo,
        category,
      },
      fields: { readingTime },
      html,
    },
    seriesParts,
    seriesRoot,
  } = data

  const { isSeriesRoot, isSeriesPart, seriesRootSlug } = pageContext

  const image = getImage(featuredImage)
  return (
    <PageContainer>
      <SEO
        title={title}
        description={description}
        imageSrc={image?.images.fallback?.src}
      />
      <BlogPost
        demo={demo}
        date={date}
        title={title}
        youTubeVideo={youTubeVideo}
        github={github}
        readingTime={readingTime.text}
        category={category}
        series={
          isSeriesPart
            ? {
                name: seriesRoot.frontmatter.title,
                slug: seriesRoot.fields.slug,
              }
            : undefined
        }
        content={
          <>
            <MarkdownHTML>
              <div dangerouslySetInnerHTML={{ __html: html }} />
              {isSeriesRoot && (
                <SeriesTableOfContent
                  parts={seriesParts.edges
                    .map((edge) => edge.node)
                    .sort(
                      (a, b) =>
                        a.frontmatter.partNumber - b.frontmatter.partNumber
                    )
                    .map((node) => {
                      return {
                        slug: node.fields.slug,
                        name: node.frontmatter.shortTitle,
                      }
                    })}
                />
              )}
            </MarkdownHTML>
          </>
        }
        coverImage={
          image ? <GatsbyImage image={image} alt={title} /> : undefined
        }
      />
      <Footer />
    </PageContainer>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath(
    $slug: String!
    $isSeries: Boolean!
    $isSeriesPart: Boolean!
    $seriesPartsSlugRegex: String
    $seriesRootSlug: String
  ) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 90, layout: CONSTRAINED)
          }
        }
        date(formatString: "MMMM D, YYYY")
        title
        youTubeVideo
        github
        title
        description
        demo
        category
      }
      fields {
        readingTime {
          text
        }
      }
    }
    seriesParts: allMarkdownRemark(
      filter: { fields: { slug: { regex: $seriesPartsSlugRegex } } }
    ) @include(if: $isSeries) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            shortTitle
            partNumber
          }
        }
      }
    }
    seriesRoot: markdownRemark(fields: { slug: { eq: $seriesRootSlug } })
      @include(if: $isSeriesPart) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

export default BlogPostTemplate
