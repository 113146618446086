import { categoryName, PostCategory } from "blog/entities/Post"
import { InternalLink } from "lib/navigation/Link/InternalLink"
import { defaultTransitionCSS } from "lib/ui/animations/transitions"
import { ChevronRightIcon } from "lib/ui/icons/ChevronRightIcon"
import { SeparatedBy } from "lib/ui/SeparatedBy"
import React from "react"
import styled from "styled-components"

interface SeriesBreadcrumb {
  name: string
  slug: string
}

interface Props {
  category: PostCategory
  series?: SeriesBreadcrumb
}

const Breadcrumb = styled(InternalLink)`
  color: ${({ theme }) => theme.colors.textSupporting.toCssValue()};
  font-size: 14px;
  ${defaultTransitionCSS};
  &:hover {
    color: ${({ theme }) => theme.colors.text.toCssValue()};
  }
`

export const BlogPostBreadcrumbs = ({ category, series }: Props) => {
  return (
    <SeparatedBy separator={<ChevronRightIcon />}>
      <Breadcrumb to="/">Home</Breadcrumb>
      <Breadcrumb to="/blog">Posts</Breadcrumb>
      <Breadcrumb to={`/blog/${category}`}>{categoryName[category]}</Breadcrumb>
      {series && <Breadcrumb to={series.slug}>{series.name}</Breadcrumb>}
    </SeparatedBy>
  )
}
