import { ExternalLink } from "lib/navigation/Link/ExternalLink"
import { NavigationText } from "lib/ui/Navigation/NavigationText"
import React from "react"

interface Props {
  url: string
}

export const DemoLink = ({ url }: Props) => {
  return (
    <ExternalLink to={url}>
      <NavigationText text="Try it!" />
    </ExternalLink>
  )
}
