import { ExternalLink } from "lib/navigation/Link/ExternalLink"
import { GitHubIcon } from "lib/ui/icons/GitHubIcon"
import { NavigationText } from "lib/ui/Navigation/NavigationText"
import React from "react"

interface Props {
  url: string
}

export const GithubLink = ({ url }: Props) => {
  return (
    <ExternalLink to={url}>
      <NavigationText text="Source Code" icon={<GitHubIcon />} />
    </ExternalLink>
  )
}
