import { defaultTransitionCSS } from "lib/ui/animations/transitions"
import styled from "styled-components"
import { codePreviewCSS } from "./codePreviewCSS"

export const MarkdownHTML = styled.section`
  ${codePreviewCSS};

  font-size: 18px;

  color: ${({ theme }) => theme.colors.text.toCssValue()};

  h2,
  h3,
  h4 {
    margin-top: 32px;
    margin-bottom: 4px;
  }

  strong {
    font-weight: 600;
  }

  img {
    max-width: 100%;
    border-radius: 16px;
  }

  .gatsby-resp-image-background-image {
    border-radius: 16px;
  }

  .gatsby-resp-image-figure {
    overflow: hidden;
  }

  a {
    color: ${({ theme }) => theme.colors.text.toCssValue()};
    font-weight: bold;
    border-bottom-color: ${({ theme }) =>
      theme.colors.text.toCssValue()} !important;
    ${defaultTransitionCSS};
    &:hover {
      color: ${({ theme }) => theme.colors.attention.toCssValue()};
    }
  }

  blockquote {
    margin: 36px 0 18px 0;
    color: ${({ theme }) => theme.colors.textSupporting.toCssValue()};
    font-size: 22px;
  }

  p,
  li {
    margin-bottom: 18px;
    line-height: 32px;
  }

  figcaption {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: -20px;
    justify-content: center;
    font-size: 14px;
  }

  figure:first-of-type + p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      margin: 10px;
      font-weight: bold;
    }
  }

  a {
    /* text-decoration: none; */
    color: ${({ theme }) => theme.colors.text.toCssValue()};
    ${defaultTransitionCSS};
    border-bottom: ${({ theme }) => theme.colors.text.toCssValue()};
    &:hover {
      color: ${({ theme }) => theme.colors.primary.toCssValue()};
      border-color: ${({ theme }) => theme.colors.primary.toCssValue()};
    }
  }

  .gatsby-resp-image-wrapper {
    margin-bottom: 24px;
  }

  .gatsby-resp-image-figcaption {
    text-align: center;
    color: ${({ theme }) =>
      theme.colors.textSupporting.toCssValue()} !important;
  }
`
