import { PostCategory } from "blog/entities/Post"
import { Center } from "lib/ui/Center"
import { PageSlice } from "lib/ui/page/PageSlice"
import { dotSeparator, SeparatedBy } from "lib/ui/SeparatedBy"
import { VStack } from "lib/ui/Stack"
import { Text } from "lib/ui/Text"
import React, { ReactNode } from "react"
import { Promotion } from "shared/components/Promotion"
import styled from "styled-components"
import { BlogPostBreadcrumbs } from "./BlogPostBreadcrumbs"
import { CoverImageHolder } from "./CoverImageHolder"
import { DemoLink } from "./DemoLink"
import { GithubLink } from "./GithubLink"
import { YouTubePromo } from "./YouTubePromo"

interface SeriesInfo {
  name: string
  slug: string
}

interface Props {
  date: string
  title: string
  coverImage?: ReactNode
  readingTime: string
  youTubeVideo?: string
  github?: string
  demo?: string
  content: ReactNode
  category: PostCategory
  series?: SeriesInfo
}

const Cotainer = styled(VStack)`
  max-width: 692px;
  width: 100%;
`

export const BlogPost = ({
  date,
  title,
  coverImage,
  youTubeVideo,
  readingTime,
  content,
  github,
  demo,
  category,
  series,
}: Props) => {
  const renderImage = () => {
    if (!coverImage) {
      return null
    }

    if (youTubeVideo) {
      return <YouTubePromo image={coverImage} url={youTubeVideo} />
    }

    return <CoverImageHolder>{coverImage}</CoverImageHolder>
  }
  return (
    <PageSlice>
      <Promotion />
      <Center>
        <Cotainer gap={32}>
          <div />
          <VStack gap={8}>
            <BlogPostBreadcrumbs series={series} category={category} />
            <Text as="h1">{title}</Text>
            <Text as="div" color="supporting" weight="bold">
              <SeparatedBy separator={dotSeparator}>
                <Text>{date}</Text>
                <Text>{readingTime}</Text>
                {github && <GithubLink url={github} />}
                {demo && <DemoLink url={demo} />}
              </SeparatedBy>
            </Text>
          </VStack>
          {renderImage()}
          {content}
          <div />
        </Cotainer>
      </Center>
    </PageSlice>
  )
}
