import React from "react"
import { ExternalLink } from "lib/navigation/Link/ExternalLink"
import { ReactNode } from "react"
import { CoverImageHolder } from "./CoverImageHolder"
import styled from "styled-components"
import { centerContentCSS } from "lib/ui/utils/centerContentCSS"
import { defaultTransitionCSS } from "lib/ui/animations/transitions"
import { YouTubeIcon } from "lib/ui/icons/YouTubeIcon"
import { HStack } from "lib/ui/Stack"

interface Props {
  image: ReactNode
  url: string
}

const TextPrompt = styled(ExternalLink)`
  ${defaultTransitionCSS};

  position: absolute;
  bottom: 0;
  padding: 12px 16px 4px 16px;
  border-radius: 8px 8px 0 0;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.background.toCssValue()};
  color: ${({ theme }) => theme.colors.text.toCssValue()};
  border: 2px solid ${({ theme }) => theme.colors.text.toCssValue()};
  border-bottom: 0;

  &:hover {
    padding: 16px 24px 4px 24px;
    border-radius: 12px 20px 0 0;
    color: ${({ theme }) => theme.colors.text.toCssValue()};
  }
`

const Container = styled(CoverImageHolder)`
  box-shadow: none;
  position: relative;

  ${centerContentCSS};
`

const IconWr = styled.div`
  color: #ff0000;
  font-size: 32px;
`

export const YouTubePromo = ({ image, url }: Props) => {
  return (
    <Container>
      {image}
      <TextPrompt to={url}>
        <HStack alignItems="center" gap={8}>
          <IconWr>
            <YouTubeIcon />
          </IconWr>
          Watch on YouTube
        </HStack>
      </TextPrompt>
    </Container>
  )
}
