import React from "react"
import { InteractivePromotion } from "lib/ui/InteractivePromotion"
import {
  PersistentStorageKey,
  usePersistentStorageValue,
} from "state/persistentStorage"
import { ShowAfterDelay } from "lib/ui/ShowAfterDelay"
import { increaserWebsite } from "shared/externalResources"
import { StaticImage } from "gatsby-plugin-image"
import { useIsScreenWidthLessThan } from "lib/ui/hooks/useIsScreenWidthLessThan"

export const Promotion = () => {
  const [wasShown, setWasShown] = usePersistentStorageValue(
    PersistentStorageKey.Promotion,
    false
  )

  const isSmallScreen = useIsScreenWidthLessThan(800)

  if (isSmallScreen) return null

  if (wasShown) return null

  return (
    <ShowAfterDelay ms={3000}>
      <InteractivePromotion
        onDismiss={() => setWasShown(true)}
        onAccept={() => setWasShown(true)}
        url={increaserWebsite}
        text="Hi there! Can I share a tool for deep work with you?"
        character={<StaticImage alt="Hello!" src="../../images/hello.png" />}
      />
    </ShowAfterDelay>
  )
}
